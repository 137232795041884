<template>
  <b-card v-if="respData" class="blog-edit-wrapper">

    <!-- form -->
    <b-form v-if="$ability.can('read', this.$route.meta.resource)" class="mt-2">
      <b-row>
        <b-col cols="12" class="mb-2">
          <div class="border rounded p-2">
            <h4 class="mb-1">
              {{ $t('Image') }}
            </h4>
            <b-media no-body vertical-align="center" class="flex-column flex-md-row">
              <b-media-aside>
                <b-img v-if="respData.image" ref="previewEl" rounded :src="respData.image" height="100" />
                <b-img v-else ref="previewEl" :src="require('@/assets/images/blank/no_image.png')" rounded
                  height="100" />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }}
                  300KB.</small>
                <b-card-text class="my-50">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                    @click="$refs.refInputEl.$el.click()">
                    {{ $t("Upload") }}
                  </b-button>
                  <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm"
                    class="mb-75 mr-75" @click="resetImage">
                    {{ $t("Reset") }}
                  </b-button>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file ref="refInputEl" accept=".webp, .jpeg, .jpg, .png, .gif" :hidden="true" plain
                    @change="inputImageRenderer" />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
        <b-col cols="12">
          <b-tabs v-model="tabIndex">
            <b-tab v-for="(itemLang, indexLang) in language" :key="indexLang" :title="$t(itemLang.name)">
              <b-form class="mt-2">
                <b-row>
                  <b-col md="6">
                    <b-form-group :label="$t('Name')" label-for="name" class="mb-2">
                      <b-form-input v-if="respData.name[indexLang]" :id="itemLang.name"
                        :value="respData.name[indexLang]['value']" :name="itemLang.name"
                        :placeholder="$t(itemLang.name)" @input="
                          inputLanguage(
                            $event,
                            itemLang.lang,
                            indexLang,
                            'name',
                          )
                          " />
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group :label="$t('Action')" label-for="register-action">
                      <v-select id="register-action" v-model="respData.action"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="OptionAction"
                        :reduce="(OptionAction) => OptionAction.value" class="select-size-lg" />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="respData.action === 'link'" cols="12">
                    <b-form-group :label="$t('Target')" label-for="register-target">
                      <v-select id="register-target" v-model="respData.target"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="OptionTarget"
                        :reduce="(OptionTarget) => OptionTarget.value" class="select-size-lg" />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="respData.action === 'link' || respData.action === 'radio'" cols="12">
                    <b-form-group :label="$t('Url Link')" label-for="register-urlLink">
                      <b-form-input id="register-urlLink" v-model="respData.urlLink" name="register-urlLink"
                        :placeholder="$t('Url Link')" />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="respData.action === 'flutter'" md="12">
                    <b-form-group :label="$t('renderBy')" label-for="register-renderBy">
                      <v-select id="register-renderBy" v-model="respData.renderBy"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="OptionRenderBy"
                        :reduce="(OptionRenderBy) => OptionRenderBy.value" class="select-size-lg" />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label="$t('Detail')" label-for="Detail" class="mb-2">
                      <!-- firstName -->
                      <b-form-group :label="$t(itemLang.name)" :label-for="itemLang.name">
                        <b-form-input v-if="respData.description[indexLang]" :id="itemLang.name"
                          :value="respData.description[indexLang]['value']" :name="itemLang.name"
                          :placeholder="$t(itemLang.name)" @input="
                            inputLanguage(
                              $event,
                              itemLang.lang,
                              indexLang,
                              'description',
                            )
                            " />
                      </b-form-group>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="respData.renderBy === 'html'" cols="4" class="mb-2">

                    <div class="code">
                      <div class="html-code">
                        <b-tabs>
                          <b-tab :title="'Editor'">
                            <h1>Editor</h1>

                            <vue-editor v-if="respData.html[indexLang]" :id="`editor${itemLang.lang}`"
                              :ref="`editor_${itemLang.lang}`" :value="respData.html[indexLang]['value']"
                              :editor-toolbar="customToolbar" @input="
                                inputLanguage(
                                  $event,
                                  itemLang.lang,
                                  indexLang,
                                  'htmlCode',
                                )
                                " />
                          </b-tab>
                          <b-tab :title="'HTML'">
                            <h1>HTML</h1>
                            <b-form-textarea v-if="respData.htmlCode[indexLang]"
                              :value="respData.htmlCode[indexLang]['value']" @input="
                                inputLanguage(
                                  $event,
                                  itemLang.lang,
                                  indexLang,
                                  'htmlCode',
                                )
                                " />
                          </b-tab>
                        </b-tabs>

                      </div>
                      <div class="css-code">
                        <h1>CSS</h1>
                        <b-form-textarea v-if="respData.htmlCSS[indexLang]"
                          :value="respData.htmlCSS[indexLang]['value']" @input="
                            inputLanguage(
                              $event,
                              itemLang.lang,
                              indexLang,
                              'htmlCSS',
                            )
                            " />
                      </div>
                    </div>

                  </b-col>
                  <b-col v-if="respData.renderBy === 'html'" cols="8" class="mb-2">
                    <h1>{{ $t('Example') }}</h1>
                    <iframe :id="`htmlRender_${itemLang.lang}`" style="width: 100%;
                        height: 100%;
                        border: none;
                        border-radius:4px;
                        border:solid 0.5px #999;
                        " />
                  </b-col>

                  <b-col v-if="respData.action === 'radio'" cols="12" class="mb-2">
                    <b-form-group :label="$t('Multiple Screen')" label-for="register-multiple-screen">
                      <v-select id="register-multiple-screen" v-model="respData.multipleScreen"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="MultipleScreenTarget"
                        :reduce="(MultipleScreenTarget) => MultipleScreenTarget.value" class="select-size-lg" />
                    </b-form-group>

                    <!-- v-if="respData.multipleScreen === 'multiple'" -->
                    <b-row>
                      <b-col v-for="(screen, index) in respData.imageMultipleScreen" :key="index" cols="4" class="mb-2">
                        <div class="border rounded p-2">
                          <h4 class="mb-1">
                            {{ $t('Size') }}: {{ screen.size }}
                          </h4>
                          <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                            <b-media-aside>
                              <b-img v-if="screen.url" :src="screen.url" rounded height="100"
                                :alt="'Screen_' + screen.size" />
                              <b-img v-else :src="require('@/assets/images/blank/no_image.png')" rounded height="100"
                                :alt="'Screen_' + screen.size" />
                            </b-media-aside>

                            <b-media-body>
                              <small class="text-muted">
                                {{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB.
                              </small>
                              <b-card-text class="my-50">
                                <!-- Upload Button -->
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm"
                                  class="mb-75 mr-75" @click="triggerFileInputMultiple(index)">
                                  {{ $t("Upload") }}
                                </b-button>

                                <!-- Reset Button -->
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                                  size="sm" class="mb-75 mr-75" @click="resetImageMultiple(index)">
                                  {{ $t("Reset") }}
                                </b-button>
                              </b-card-text>

                              <!-- Hidden File Input -->
                              <div class="d-inline-block">
                                <b-form-file :ref="'fileInputMultiple_' + index" accept=".webp, .jpeg, .jpg, .png, .gif"
                                  :hidden="true" plain multiple @change="inputImageRendererMultiple($event, index)" />
                              </div>
                            </b-media-body>
                          </b-media>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="12" class="mt-50">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                      @click="saveChanges">
                      {{ $t('Save Changes') }}
                    </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="cancelBtn">
                      {{ $t('Cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-col>

        <!-- <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              {{ $t('Image') }}
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  v-if="respData.image"
                  ref="previewEl"
                  rounded
                  :src="respData.image"
                  height="100"
                />
                <b-img
                  v-else
                  ref="previewEl"
                  :src="require('@/assets/images/blank/no_image.png')"
                  rounded
                  height="100"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted">{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB.</small>
                <b-card-text class="my-50">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    {{ $t("Upload") }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="resetImage"
                  >
                    {{ $t("Reset") }}
                  </b-button>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    accept=".webp, .jpeg, .jpg, .png, .gif"
                    :hidden="true"
                    plain
                    @change="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('Title')"
            label-for="title"
            class="mb-2"
          >
            <b-form-input
              id="title"
              v-model="respData.title"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            :label="$t('Action')"
            label-for="register-action"
          >
            <v-select
              id="register-action"
              v-model="respData.action"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="OptionAction"
              :reduce="(OptionAction) => OptionAction.value"
              class="select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="respData.action === 'link'"
          cols="12"
        >
          <b-form-group
            :label="$t('Target')"
            label-for="register-target"
          >
            <v-select
              id="register-target"
              v-model="respData.target"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="OptionTarget"
              :reduce="(OptionTarget) => OptionTarget.value"
              class="select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="respData.action === 'link'"
          cols="12"
        >
          <b-form-group
            :label="$t('Url Link')"
            label-for="register-urlLink"
          >
            <b-form-input
              id="register-urlLink"
              v-model="respData.urlLink"
              name="register-urlLink"
              :placeholder="$t('Url Link')"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="respData.action === 'flutter'"
          md="12"
        >
          <b-form-group
            :label="$t('renderBy')"
            label-for="register-renderBy"
          >
            <v-select
              id="register-renderBy"
              v-model="respData.renderBy"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="OptionRenderBy"
              :reduce="(OptionRenderBy) => OptionRenderBy.value"
              class="select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
        > <b-form-group
          :label="$t('Name')"
          label-for="name"
          class="mb-2"
        >
          <b-col
            v-for="(item , index) in language"
            :key="index"
            cols="12"
          >
            <b-form-group
              :label="$t(item.name)"
              :label-for="item.name"
            >
              <b-form-input
                v-if="respData.name[index]"
                :id="item.name"
                :name="item.name"
                :value="respData.name[index]['value']"
                :placeholder="$t(item.name)"
                @input="inputLanguage($event,item.lang ,index,'name')"
              />
            </b-form-group>
          </b-col>
        </b-form-group>
        </b-col>
        <b-col
          md="12"
        > <b-form-group
          :label="$t('Description')"
          label-for="description"
          class="mb-2"
        >
          <b-col
            v-for="(item , index) in language"
            :key="index"
            cols="12"
          >
            <b-form-group
              :label="$t(item.name)"
              :label-for="item.name"
            >
              <b-form-input
                v-if="respData.description[index]"
                :id="item.name"
                :name="item.name"
                :value="respData.description[index]['value']"
                :placeholder="$t(item.name)"
                @input="inputLanguage($event,item.lang ,index ,'description')"
              />
            </b-form-group>
          </b-col>
        </b-form-group>
        </b-col>
        <b-col
          v-if="respData.action ==='flutter' && respData.renderBy === 'flutter'"
          md="12"
        >
          <b-form-group
            :label="$t('Content')"
            label-for="content"
            class="mb-2"
          >
            <b-form-textarea
              id="content"
              v-model="respData.content"
              rows="8"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="respData.action ==='flutter' && respData.renderBy === 'html'"
          cols="12"
          class="mb-2"
        >
          <b-overlay
            :show="loadingHtml"
            rounded="sm"
          >
            <b-form-group
              label="Content"
              label-for="blog-content"
              class="mb-2"
            >

              <b-form-file
                id="getFile"
                accept=".webp, .jpeg, .jpg, .png, .gif"
                :hidden="true"
                plain
                @change="uploadFunction($event)"
              />
            </b-form-group>
            <b-form-textarea
              id="textarea-state"
              v-model="htmlData"
              rows="8"
            />
            <span class="mr-25 align-middle text-danger">*{{ $t("PLEASE_SAVE_HTML_AFTER_SAVE_CHANGE") }}</span>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1"
              @click="saveHTML"
            >
              {{ $t('Save HTML') }}
            </b-button>
          </b-overlay>

        </b-col> -->

        <!-- <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="saveChanges"
          >
            {{ $t('Save Changes') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="cancelBtn"
          >
            {{ $t('Cancel') }}
          </b-button>
        </b-col> -->
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormTextarea, BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import StoreModule from '@/store/services/eCommerce/subMenu'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
// eslint-disable-next-line import/no-unresolved

import { VueEditor } from 'vue2-editor'

const STORE_MODULE_NAME = 'subMenu'

export default {
  components: {
    VueEditor,

    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BTabs,
    BTab,
    // quillEditor,
    // Editor,
    BFormTextarea,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BMediaAside,
    // eslint-disable-next-line vue/no-unused-components
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      dataId: null,
      dataIdMenu: null,
      htmlData: '',
      respData: {
        image: '',
        title: '',
        content: '',
        name: [],
        description: [],
        renderBy: 'html',
        htmlData: '',
        html: [],
        htmlCode: [],
        htmlCSS: [],
        action: 'none',
        target: 'IN_APP',
        urlLink: '',
        multipleScreen: 'single',
        imageMultipleScreen: [
          {
            size: '1x1',
            url: '',
          },
          {
            size: '2x2',
            url: '',
          },
          {
            size: '4x4',
            url: '',
          },
        ],
      },
      loadingHtml: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // [
        //   { align: '' },
        //   { align: 'center' },
        //   { align: 'right' },
        //   { align: 'justify' },
        // ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'image', 'video'],
        ['clean'], // remove formatting button
      ],

    }
  },
  computed: {
    OptionRenderBy() {
      return [
        { name: 'Flutter', value: 'flutter' },
        { name: 'Html', value: 'html' },
      ]
    },
    OptionAction() {
      return [
        { name: this.$t('None'), value: 'none' },
        { name: this.$t('Flutter'), value: 'flutter' },
        { name: this.$t('Link'), value: 'link' },
        { name: this.$t('Radio'), value: 'radio' },
      ]
    },
    MultipleScreenTarget() {
      return [
        { name: this.$t('Single'), value: 'single' },
        { name: this.$t('Multiple'), value: 'multiple' },
      ]
    },
    OptionTarget() {
      return [
        { name: this.$t('In App'), value: 'IN_APP' },
        { name: this.$t('External App'), value: 'EXTERNAL_APP' },
      ]
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  watch: {
    tabIndex(val) {
      if (this.respData.htmlCode.length > 0 && this.respData.htmlCSS.length > 0) {
        this.renderCode(val)
      }
    },
  },
  created() {
    const IdMenu = this.$route.params.idMenu // Menu
    const Id = this.$route.params.id // subMenu
    this.dataId = Id
    this.dataIdMenu = IdMenu
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    if (this.dataId !== 'add') {
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, { id: this.dataId })
        .then(result => {
          if (result.data.data) {
            // renderBy
            this.respData = result.data.data
            console.log(result.data.data)

            // this.respData.htmlCode = this.respData.multipleScreen
            // this.respData.htmlCode = this.respData.html


            if (this.respData.htmlCode.length < 1) {
              this.respData.htmlCSS = [{
                lang: 'th',
                value: '',
              },
              {
                lang: 'en',
                value: '',
              },
              ]
              this.respData.htmlCode = this.respData.html

              if (this.respData.htmlCode.length !== this.language.length) {
                for (let index = 0; index < this.language.length; index += 1) {
                  const indexLanguage = this.respData.htmlCode.findIndex(element => element.lang === this.language[index].lang)
                  if (indexLanguage < 0) {
                    this.respData.htmlCode.push({
                      lang: this.language[index].lang, // th , en
                      value: '',
                    })
                  }
                }
                for (let index = 0; index < this.respData.htmlCode.length; index += 1) {
                  const indexLanguage = this.language.findIndex(element => element.lang === this.respData.htmlCode[index].lang)
                  if (indexLanguage < 0) {
                    this.respData.htmlCode.splice(index, 1)
                  }
                }
              }
            }
            if (this.respData.htmlCSS.length < 1) {
              this.respData.htmlCSS = [{
                lang: 'th',
                value: '',
              },
              {
                lang: 'en',
                value: '',
              },
              ]
              if (this.respData.htmlCSS.length !== this.language.length) {
                for (let index = 0; index < this.language.length; index += 1) {
                  const indexLanguage = this.respData.htmlCSS.findIndex(element => element.lang === this.language[index].lang)
                  if (indexLanguage < 0) {
                    this.respData.htmlCSS.push({
                      lang: this.language[index].lang, // th , en
                      value: '',
                    })
                  }
                }
                for (let index = 0; index < this.respData.htmlCSS.length; index += 1) {
                  const indexLanguage = this.language.findIndex(element => element.lang === this.respData.htmlCSS[index].lang)
                  if (indexLanguage < 0) {
                    this.respData.htmlCSS.splice(index, 1)
                  }
                }
              }
            }
            const indexRender = this.language.findIndex(e => e.lang === this.$i18n.locale)
            this.renderCode(indexRender)

            if (this.respData.name.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.respData.name.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.respData.name.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.respData.name.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.respData.name[index].lang)
                if (indexLanguage < 0) {
                  this.respData.name.splice(index, 1)
                }
              }
            }
            if (this.respData.description.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.respData.description.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.respData.description.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.respData.description.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.respData.description[index].lang)
                if (indexLanguage < 0) {
                  this.respData.description.splice(index, 1)
                }
              }
            }
            if (this.respData.html.length !== this.language.length) {
              for (let index = 0; index < this.language.length; index += 1) {
                const indexLanguage = this.respData.html.findIndex(element => element.lang === this.language[index].lang)
                if (indexLanguage < 0) {
                  this.respData.html.push({
                    lang: this.language[index].lang, // th , en
                    value: '',
                  })
                }
              }
              for (let index = 0; index < this.respData.html.length; index += 1) {
                const indexLanguage = this.language.findIndex(element => element.lang === this.respData.html[index].lang)
                if (indexLanguage < 0) {
                  this.respData.html.splice(index, 1)
                }
              }
            }
          }
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    } else {
      if (this.respData.name.length !== this.language.length) {
        for (let index = 0; index < this.language.length; index += 1) {
          const indexLanguage = this.respData.name.findIndex(element => element.lang === this.language[index].lang)
          if (indexLanguage < 0) {
            this.respData.name.push({
              lang: this.language[index].lang, // th , en
              value: '',
            })
          }
        }
      }
      if (this.respData.description.length !== this.language.length) {
        for (let index = 0; index < this.language.length; index += 1) {
          const indexLanguage = this.respData.description.findIndex(element => element.lang === this.language[index].lang)
          if (indexLanguage < 0) {
            this.respData.description.push({
              lang: this.language[index].lang, // th , en
              value: '',
            })
          }
        }
      }
      if (this.respData.html.length !== this.language.length) {
        for (let index = 0; index < this.language.length; index += 1) {
          const indexLanguage = this.respData.html.findIndex(element => element.lang === this.language[index].lang)
          if (indexLanguage < 0) {
            this.respData.html.push({
              lang: this.language[index].lang, // th , en
              value: '',
            })
          }
        }
      }
      if (this.respData.htmlCode.length !== this.language.length) {
        for (let index = 0; index < this.language.length; index += 1) {
          const indexLanguage = this.respData.htmlCode.findIndex(element => element.lang === this.language[index].lang)
          if (indexLanguage < 0) {
            this.respData.htmlCode.push({
              lang: this.language[index].lang, // th , en
              value: '',
            })
          }
        }
      }
      if (this.respData.htmlCSS.length !== this.language.length) {
        for (let index = 0; index < this.language.length; index += 1) {
          const indexLanguage = this.respData.htmlCSS.findIndex(element => element.lang === this.language[index].lang)
          if (indexLanguage < 0) {
            this.respData.htmlCSS.push({
              lang: this.language[index].lang, // th , en
              value: '',
            })
          }
        }
      }
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    renderCode(indexLang) {
      const locale = this.language[indexLang].lang
      const result = document.querySelector(`#htmlRender_${locale}`)

      const htmlCode = this.respData.htmlCode[indexLang].value
      const htmlCSS = this.respData.htmlCSS[indexLang].value

      result.contentDocument.body.innerHTML = `<style>${htmlCSS}</style>${htmlCode}`
    },
    inputLanguage(input, lang, index, type) {
      console.log(input, lang, index, type)

      if (type === 'name') {
        if (this.respData.name[index].lang === lang) {
          this.respData.name[index].value = input
        }
      } else if (type === 'description') {
        if (this.respData.description[index].lang === lang) {
          this.respData.description[index].value = input
        }
      } else if (type === 'htmlCode') {
        if (this.respData.htmlCode[index].lang === lang) {
          this.respData.htmlCode[index].value = input
          this.renderCode(index)
        }
      } else if (type === 'htmlCSS') {
        if (this.respData.htmlCSS[index].lang === lang) {
          this.respData.htmlCSS[index].value = input
          this.renderCode(index)
        }
      }
    },
    // saveHTML() {
    //   this.respData.htmlData = this.htmlData
    // },
    uploadFunction(input) {
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }
        this.loadingHtml = true
        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          const obj = { image: e.target.result }
          this.$store
            .dispatch(`${STORE_MODULE_NAME}/uploadImage`, obj)
            .then(resp => {
              this.loadingHtml = false

              const range = this.$refs.quillEdit.quill.getSelection()
              this.$refs.quillEdit.quill.insertEmbed(range.index, 'image', resp.data.url)
            })
            .catch(err => {
              this.loadingHtml = false

              console.error(err)
            })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    saveChanges() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            const html = [{
              lang: 'th',
              value: '',
            },
            {
              lang: 'en',
              value: '',
            }]
            const obj = {
              id: this.dataId,
              idMenu: this.dataIdMenu,
              image: this.respData.image,
              name: this.respData.name,
              description: this.respData.description,
              renderBy: this.respData.renderBy,
              title: this.respData.title,
              content: this.respData.content,
              htmlData: this.respData.htmlData,
              action: this.respData.action,
              target: this.respData.target,
              urlLink: this.respData.urlLink,
              html: html.map((e, index) => ({
                lang: e.lang,
                value: `<style>${this.respData.htmlCSS[index].value}</style>${this.respData.htmlCode[index].value}`,
              })),
              htmlCode: this.respData.htmlCode,
              htmlCSS: this.respData.htmlCSS,
              multipleScreen: this.respData.multipleScreen,
              imageMultipleScreen: this.respData.imageMultipleScreen,
            }
            if (this.dataId !== null && this.dataId !== 'add') {
              store
                .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                .then(result => {
                  this.show = false
                  this.$router.go(-1)
                }).catch(error => {
                  this.show = false
                  console.log('fetchUsers Error : ', error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            } else {
              delete obj.id
              store
                .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                .then(result => {
                  this.show = false
                  this.$router.go(-1)
                }).catch(error => {
                  this.show = false
                  console.log('fetchUsers Error : ', error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            }
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    cancelBtn() {
      this.$router.go(-1)
    },
    showHtml() {
      console.log(this.respData.htmlData)
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 300000) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     position: 'bottom-right',
        //     props: {
        //       title: this.$t('Error'),
        //       icon: 'ErrorIcon',
        //       variant: 'danger',
        //       text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
        //     },
        //   })
        //   return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          this.respData.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    // Trigger file input dialog for multiple files
    triggerFileInputMultiple(index) {
      // const fileInput = this.$refs['fileInputMultiple_' + index]; 
      // if (fileInput) {
      //   fileInput.click(); // Open the file input dialog
      // }

      this.$refs['fileInputMultiple_' + index].$el.click()
    },

    // Handle multiple file input change
    inputImageRendererMultiple(event, index) {
      const files = event.target.files;
      if (files && files.length > 0) {
        const urls = [];
        Array.from(files).forEach((file) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            urls.push(e.target.result);
            // Update the array when all files are loaded
            if (urls.length === files.length) {
              this.$set(this.respData.imageMultipleScreen[index], 'url', urls[0]); // Store the first image for simplicity
            }
          };
          reader.readAsDataURL(file);
        });
      }
    },

    // Reset images for a particular index
    resetImageMultiple(index) {
      this.$set(this.respData.imageMultipleScreen[index], 'url', ''); // Reset to empty string or default value
    },
    inputChange(name) {
      const obj = {}
      console.log(name)

      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {

        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {

        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.ql-container {
  height: 250px;
}
</style>
